import React from "react";
import RemoImage from "../../assets/Remo.png"; // Remo Bild importieren
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="app__footer">
      <div className="footer-container">
        <div className="footer-info">
          <h2 className="footer-title">King vom Usflug</h2>
          <img src={RemoImage} alt="King vom Usflug" className="remo-image" />
        </div>
      </div>
    </footer>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "02. contact",
  "app__whitebg" // Hintergrund wieder auf Weiß gesetzt
);
