import React, { useState } from "react";
import { Header, Footer, Survey } from "./container";
import { Navbar } from "./components";
import Login from './components/login';  // Importiere die Login-Komponente korrekt
import "./App.scss";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <div className="app">
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} />
      ) : (
        <>
          <Header />
          <Survey />
          <div className="app__footerheight">
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default App;
