import React, { useState, useEffect } from "react";
import { AppWrap } from "../../wrapper";
import porscheImage from "../../assets/porsche.jpg";
import "./Survey.scss";

const Survey = () => {
  const targetDate = new Date("2024-09-22T00:00:00").getTime(); // Zielzeitpunkt
  const [timeLeft, setTimeLeft] = useState(targetDate - new Date().getTime());
  const [hasTimerEnded, setHasTimerEnded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const difference = targetDate - currentTime;

      if (difference <= 0) {
        setHasTimerEnded(true); // Timer abgelaufen
        clearInterval(interval);
      } else {
        setTimeLeft(difference); // Timer läuft weiter
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const formatTimeLeft = (milliseconds) => {
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

    return `${days} Tage ${hours} Stunden ${minutes} Minuten ${seconds} Sekunden`;
  };

  return (
    <div id="survey" className="app__survey app__flex">
      {hasTimerEnded ? (
        <div className="image-display">
          <img src={porscheImage} alt="Porsche" />
          <h2>vroom vroom! Heute gehen wir Autos schauen!</h2>
        </div>
      ) : (
        <div className="countdown-timer">
          <h1>Rätsel 3</h1>
          <p>{formatTimeLeft(timeLeft)}</p>
        </div>
      )}
    </div>
  );
};

export default AppWrap(Survey, "02. survey");
