import React from "react";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import "./Header.scss";
import "../../scripts/mouse/mousemove";

const TEXTS = [
  "Web Engineer",
  "Software Engineer",
  "Design",
  "Software Design",
  "Software Architecture",
];

const Header = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div id="home" className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info">
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <div style={{ marginLeft: 20 }}>
              <p className="p-first">Woane geit's äch?</p>
              <h1 className="head-text">Remo's Junggesellen-</h1>
              <h1 className="head-text">abschied</h1>
              <h1 className="head-text-second">
                Rätsel 1
              </h1>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, "01. home");
