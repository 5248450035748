import React, { useState } from 'react';
import './login.scss';

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const correctPassword = '04.08.1998';  // Setze hier das gewünschte Passwort

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      alert('Grux <-  :)');
      onLogin();  // Rufe die Login-Funktion auf
    } else {
      alert('Grux <-  >:(');
    }
  };

  return (
    <div className="login-container">
      <h2 className="remo">nfjkldgsngadsklhgöh_</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Passwort"
          className="login-input"
        />
        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
};

export default Login;
